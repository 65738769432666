define(['app', 'siteObj'], (app, siteObj) => {
  const ProductRevooReviewStars = () => {

    const component = {};

    const _init = (element) => {
      component.element = element;

      if (siteObj.showReevooReviews) {
        require(['reevooClient']);
      }

    };

    component.init = _init;

    return component;
  };
  return ProductRevooReviewStars;
});
